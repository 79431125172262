<template>
  <div>
    <el-dialog :append-to-body="true" :visible.sync="dialog" title="返还保证金" :close-on-click-modal="false" @closed="cancel" width="560px">
      <el-form ref="form" :model="form" :rules="rules" label-position="right" label-width="115px" label-suffix=":" v-if="form" :disabled="disabled">
        <!-- payMoney > form.rebackMoney-->
        <el-form-item prop="rebackMoney" label="返还金额">
          <price-input v-model="form.rebackMoney" :min="0" :max="maxPayMoney" :step-ratio="10000" style="width: 240px" />
        </el-form-item>
        <el-form-item prop="toMoneyAccount" label="返回现金账户">
          <el-radio-group v-model="form.toMoneyAccount" style="width: 240px">
            <el-radio :label="true">是</el-radio>
            <el-radio :label="false">否</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="text" @click="dialog = false">取消</el-button>
        <el-button :loading="loading" type="primary" @click="doSave">保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import request from "@/utils/request";
export default {
  mounted() {},
  data() {
    return {
      loading: false,
      searching: false,
      dialog: false,
      disabled: false,
      maxPayMoney: null,
      form: null,
      rules: {
        rebackMoney: [{ required: true, message: "请填写返还金额" }],
        toMoneyAccount: [{ required: true, message: "选择是否返回现金账户" }],
      },
    };
  },
  methods: {
    cancel() {
      this.form = null;
    },
    doSave() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.loading = true;
          let data = {
            id: this.form.id,
            rebackMoney: this.form.rebackMoney,
            toMoneyAccount: this.form.toMoneyAccount,
          };
          request({
            url: "api/deposit/reback",
            method: "post",
            data,
          })
            .then((res) => {
              this.dialog = false;
              this.$notify({
                title: "返还保证金成功",
                type: "success",
                duration: 2500,
              });
              this.loading = false;
              this.$parent.init();
            })
            .catch((err) => {
              this.loading = false;
              console.log(err.response.data.message);
            });
        }
      });
    },
    restForm(form) {
      if (form) {
        this.dialog = true;
        this.disabled = false;
        this.form = {
          id: form.id,
          rebackMoney: form.payMoney,
          toMoneyAccount: true,
        };
        this.maxPayMoney = form.payMoney / 100;
      }
    },
  },
};
</script>

